import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from './template/Header';
import Footer from './template/Footer';
import Home from './screens/Home';
import Register from './screens/Register';
import './Bootstrap/bootstrap.min.css';
import './index.css';
import axios from "axios";
import {Helmet} from "react-helmet";
import {BrowserRouter as Router,Routes,Route,Link,useParams} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <Router>
          <Helmet>
            <title>Larin</title>
          </Helmet>
          <Routes>
            <Route exact path="/" element={
              <div>
                <Header />
                <Home />
                <Footer />
              </div>
            } />
            <Route exact path="/register" element={
              <div>
                <Register />
              </div>
            } />
          </Routes>
        </Router>
    );