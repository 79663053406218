import Carousel from 'react-bootstrap/Carousel';
import Banner1 from '../images/banners/banner1.png';
import Banner2 from '../images/banners/banner2.png';
import Banner3 from '../images/banners/banner3.png';
import arrow from '../images/arrow.svg';
import './banner.css';
import {Link} from "react-router-dom";

function Banner() {
  return (
    <div>
      
      <Carousel fade indicators={false}>
        <Carousel.Item>
          <img className="d-block w-100" src={Banner1} alt="Banner 1"  />
          <Carousel.Caption>
            <div>
              <h1>Ligue-se e <span className='highlight-title'>tenha alma.</span></h1>
              <p>O espaço dedicado aos nossos avôs.</p>
              <Link to="/register" className="btn-white">Registe-se agora</Link>
              <div className='bg-white-transform'></div>
              <div className="banner-caption">
                <div class="container">
                  <h5>Mafra, Lisboa</h5>
                  <p>Casa de Repouso São José de Maria <a href="#">Ver mais <img className="link-arrow" src={arrow}/></a></p>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        {/*
        <Carousel.Item>
          <img className="d-block w-100" src={Banner2} alt="Banner 2" />
          <Carousel.Caption>
            <div>
              <h1>Ligue-se e <span className='highlight-title'>tenha alma.</span></h1>
              <p>O espaço dedicado aos nossos avôs.</p>
              <Link to="/register" className="btn-white">Registe-se agora</Link>
              <div className='bg-white-transform'></div>
              <div className="banner-caption">
                <h5>Mafra, Lisboa</h5>
                <p>Casa de Repouso São José de Maria <a href="#">Ver mais ></a></p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Banner3} alt="Banner 3"  />
          <Carousel.Caption>
            <div>
              <h1>Ligue-se e <span className='highlight-title'>tenha alma.</span></h1>
              <p>O espaço dedicado aos nossos avôs.</p>
              <Link to="/register" className="btn-white">Registe-se agora</Link>
              <div className='bg-white-transform'></div>
              <div className="banner-caption">
                <h5>Mafra, Lisboa</h5>
                <p>Casa de Repouso São José de Maria <a href="#">Ver mais ></a></p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        */}
        
      </Carousel>
      
    </div>
  );
}

export default Banner;