import React from 'react';
import '../index.css';
import '../config.js';
import Banner from '../components/Banner';
import $ from 'jquery';

import icon1 from '../images/positive-badge-icon.svg';
import icon2 from '../images/space-icon.svg';
import icon3 from '../images/rating.svg';
import icon4 from '../images/world-icon.svg';
import check from '../images/check.svg';
import sofa from '../images/sofa-icon.svg';
import diamond from '../images/diamond-icon.svg';
import hscroll1 from '../images/hscroll-1.png';
import hscroll2 from '../images/hscroll-2.png';
import hscroll3 from '../images/hscroll-3.png';
import hscroll4 from '../images/hscroll-4.png';
import hscroll5 from '../images/hscroll-5.png';
import arrowNav from '../images/arrow-navigation.svg';
import closeIcon from '../images/close-icon.svg';
import { Container, Row, Col } from 'react-bootstrap';
import {Link} from "react-router-dom";
import axios from "axios";


class Home extends React.Component {
  constructor(props){
    super(props);
		this.state = {
      bannerTitle: '',
      scrollPos: 0,
      slides: [],
		}
	}

  componentDidMount(){
		// START: GET SLIDES
		axios.get(global.config.apiUrl+"getSlides/")
		.then(res => {
			const slides = res.data;
			this.setState({ slides });
		  })
		// END: GET SLIDES
	}

  hscrollRight(){
    if((this.state.scrollPos + 300) > ($('.hscroll-container').prop('scrollWidth') - window.innerWidth)){
      $('.hscroll-arrow-right').css('display','none');
    }
    $('.hscroll-container').animate({scrollLeft: this.state.scrollPos + 300}, 500);
    $('.hscroll-arrow-left').css('display','block');
    if((this.state.scrollPos + 300) > ($('.hscroll-container').prop('scrollWidth') - window.innerWidth)) this.setState({ scrollPos: ($('.hscroll-container').prop('scrollWidth') - window.innerWidth) });
    else this.setState({ scrollPos: this.state.scrollPos + 300 });
  }

  hscrollLeft(){
    if((this.state.scrollPos - 300) <= 0){
      $('.hscroll-arrow-left').css('display','none');
    }
    $('.hscroll-container').animate({scrollLeft: this.state.scrollPos - 300}, 500);
    $('.hscroll-arrow-right').css('display','block');
    if((this.state.scrollPos - 300) <= 0) this.setState({ scrollPos: 0 });
    else this.setState({ scrollPos: this.state.scrollPos - 300 });
  }

  openAccordion(index){
    $('.subscribe .box-white .accordion-hide.index-'+index).css('display','block');
    $('.subscribe .box-white .open-accordion.index-'+index).css('display','none');
  }
  closeAccordion(index){
    $('.subscribe .box-white .accordion-hide.index-'+index).css('display','none');
    $('.subscribe .box-white .open-accordion.index-'+index).css('display','block');
  }
  
  render(){
    return (
      <div>
        <Banner />
        <Container>
          <Row className="mt-60">
            <Col md={12} className="text-center">
              <h2 className='title-h2 title-center'>Larin, o centro de <span className='highlight-title'>ligações</span> digitais, onde se encontra e se pode ser encontrado.</h2>
            </Col>
          </Row>
          <Row className="mt-60 text-center icon-text-section">
            <Col md={3} xs={6}>
              <div className='img-container'>
                <img src={icon1}/>
              </div>
              <p>Registo simples, fácil, <b>gratuito</b> e acessível</p>
            </Col>
            <Col md={3} xs={6}>
              <div className='img-container'>
                <img src={icon2}/>
              </div>
              <p>Um local onde encontra <b>espaços, serviços, eventos</b> e muito mais</p>
            </Col>
            <Col md={3} xs={6}>
            <div className='img-container'>
                <img src={icon3}/>
              </div>
              <p>Reviews e comentários reais para obter a <b>confiança de quem procura</b></p>
            </Col>
            <Col md={3} xs={6}>
              <div className='img-container'>
                <img src={icon4}/>
              </div>
              <p>Encontre e seja encontrado… <b>aqui</b> ou em <b>qualquer lugar</b></p>
            </Col>
          </Row>
          <Row className="mt-200">
            <Col md={12} className="text-center">
              <h2 className='title-h2 title-center'>Uma forma de estar ligado ao mundo digital e expandir o seu <span className='highlight-title'>alcance… </span></h2>
            </Col>
          </Row>
          <Row className="mt-40">
          <Col md={2}></Col>
            <Col md={8} className="text-center">
              <p>Através do Larin, conseguirá gerir de um modo autónomo, privado e seguro os seus espaços e serviços, acessíveis no seu raio local, nacional e até internacional. <b>De que espera?</b> <br /><br />
                Quanto mais rápido se inscrever, mais tráfego tiver nas suas páginas e mais procura alcançar, melhor ficará posicionado com os seus espaços e serviços organicamente para todos que procurarem por espaços semelhantes ao seu… <b>mesmo no plano gratuito.</b></p>
            </Col>
          </Row>
        </Container>
        <div className='subscribe mt-80'>
          <img className="icon-left" src={sofa}/>
          <img className="icon-right" src={diamond}/>
          <Container>
            <Row className="mt-40">
              <Col md={3}></Col>
              <Col md={6} className="text-center">
                <h3 className='title-h3 text-white'>Subscrição com benefícios</h3>
              </Col>
              </Row>
              <Row className="mt-60 hide-mobile">
              <Col md={4}></Col>
              <Col md={4} className="box-white">
                  <h3 className='title-h3 text-green text-center'>Grátis para <br />sempre!</h3>
                  <h5 className='title-h5 mt-30 mb-30'>Tudo o que irá receber<br />gratuitamente… mesmo:</h5>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Criação de página do seu <br />lar, centro ou espaço</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Até 3 espaços e serviços</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Aparece sempre na pesquisa Larin</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Publicidade gratuita</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Pegada digital, com benefícios do nosso expertise em marketing e SEO</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Pegada digital, com benefícios do nosso expertise em marketing e SEO</p></div>
                  <div className="subscribe-bottom">
                    <hr className='mt-60' />
                    <div className='subscribe-btn mt-60'>
                      Sempre <br /><span>Grátis</span>
                      <Link to="/register" className="btn-green">Registar agora</Link>
                    </div>
                  </div>
                </Col>
                {/* 
                <Col md={4} className="box-white middle-col">
                  <h3 className='title-h3 text-green text-center'>Com vantagens <br />extra</h3>
                  <h5 className='title-h5 mt-30 mb-30'>Para quem pretende<br />levar o negócio a sério:</h5>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Tudo do plano gratuito</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Registo de espaços e serviços sem limite</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>No mínimo, uma presença/mês nos canais de marketing Larin</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Presença garantida nas pesquisas do site Larin</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>1 artigo na revista Larin</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Produção de uma campanha por ano</p></div>
                  <div className="subscribe-bottom">
                    <hr className='mt-60' />
                    <div className='subscribe-btn mt-60'>
                      Apenas <br /><span>€000</span>/mês
                      <Link to="/register" className="btn-green">Registar agora</Link>
                    </div>
                  </div>
                  <div className="pop-option">Opção mais popular</div>
                </Col>
                <Col md={4} className="box-white">
                  <h3 className='title-h3 text-green text-center'>Com vantagens<br/>premium</h3>
                  <h5 className='title-h5 mt-30 mb-30'>Para quem pretende<br/>estar no topo:</h5>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Tudo do plano Extra</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Rebrand de imagem corporativa</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>+1 artigo na revista Larin (Portugês e Inglês)</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Maior ranking nas pesquisas do Larin que no plano Extra</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Gestão de redes sociais</p></div>
                  <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Consultadoria para melhor exposição</p></div>
                  <div className="subscribe-bottom">
                    <hr className='mt-60' />
                    <div className='subscribe-btn mt-60'>
                      Apenas <br /><span>€000</span>/mês
                      <Link to="/register" className="btn-green">Registar agora</Link>
                    </div>
                  </div>
                </Col>
                */}
              </Row>
              <Row className="mt-60 show-mobile">
              <Col md={4}></Col>
              <Col md={4} className="box-white">
                  <h3 className='title-h3 text-green text-center'>Grátis para sempre!</h3>
                  <h5 className='title-h5 mt-30 mb-30'>Tudo o que irá receber<br />gratuitamente… mesmo:</h5>
                  <div className='open-accordion index-1'>
                    <p>Criação página Espaço e Serviço, publicidade gratuita, pegad…</p>
                    <button className="btn-open-accordion" onClick={() => this.openAccordion(1)}><img src={closeIcon}/></button>
                  </div>
                  <div className='accordion-hide index-1' style={{display: 'none'}}>
                    <button className="btn-close-accordion" onClick={() => this.closeAccordion(1)}><img src={closeIcon}/></button>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Criação de página do seu <br />lar, centro ou espaço</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Até 3 espaços e serviços</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Aparece sempre na pesquisa Larin</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Publicidade gratuita</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Pegada digital, com benefícios do nosso expertise em marketing e SEO</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Pegada digital, com benefícios do nosso expertise em marketing e SEO</p></div>
                    <div className="subscribe-bottom">
                      <hr className='mt-60' />
                      <div className='subscribe-btn mt-60'>
                        Sempre <br /><span>Grátis</span>
                        <Link to="/register" className="btn-green">Registar agora</Link>
                      </div>
                    </div>
                  </div>
                </Col>
                {/* 
                <Col md={4} className="box-white middle-col">
                  <h3 className='title-h3 text-green text-center'>Com vantagens extra</h3>
                  <h5 className='title-h5 mt-30 mb-30'>Para quem pretende<br />levar o negócio a sério:</h5>
                  <div className='open-accordion index-2'>
                    <p>+ Espaços e Serviços, + pegada de marketing digital, + presen…</p>
                    <button className="btn-open-accordion" onClick={() => this.openAccordion(2)}><img src={closeIcon}/></button>
                  </div>
                  <div className='accordion-hide index-2' style={{display: 'none'}}>
                    <button className="btn-close-accordion" onClick={() => this.closeAccordion(2)}><img src={closeIcon}/></button>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Tudo do plano gratuito</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Registo de espaços e serviços sem limite</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>No mínimo, uma presença/mês nos canais de marketing Larin</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Presença garantida nas pesquisas do site Larin</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>1 artigo na revista Larin</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Produção de uma campanha por ano</p></div>
                    <div className="subscribe-bottom">
                      <hr className='mt-60' />
                      <div className='subscribe-btn mt-60'>
                        Apenas <br /><span>€000</span>/mês
                        <Link to="/register" className="btn-green">Registar agora</Link>
                      </div>
                    </div>
                  </div>
                  <div className="pop-option">Opção mais popular</div>
                </Col>
                <Col md={4} className="box-white">
                  <h3 className='title-h3 text-green text-center'>Com vantagens premium</h3>
                  <h5 className='title-h5 mt-30 mb-30'>Para quem pretende<br/>estar no topo:</h5>
                  <div className='open-accordion index-3'>
                    <p>Maior ranking no Larin, maior pegada digital, gestão nas red…</p>
                    <button className="btn-open-accordion" onClick={() => this.openAccordion(3)}><img src={closeIcon}/></button>
                  </div>
                  <div className='accordion-hide index-3' style={{display: 'none'}}>
                    <button className="btn-close-accordion" onClick={() => this.closeAccordion(3)}><img src={closeIcon}/></button>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Tudo do plano Extra</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Rebrand de imagem corporativa</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>+1 artigo na revista Larin (Portugês e Inglês)</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Maior ranking nas pesquisas do Larin que no plano Extra</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Gestão de redes sociais</p></div>
                    <div className="subscribe-inline"><img src={check} /><p className='subscribe-list'>Consultadoria para melhor exposição</p></div>
                    <div className="subscribe-bottom">
                      <hr className='mt-60' />
                      <div className='subscribe-btn mt-60'>
                        Apenas <br /><span>€000</span>/mês
                        <Link to="/register" className="btn-green">Registar agora</Link>
                      </div>
                    </div>
                  </div>
                </Col>
                */}
              </Row>
          </Container>
        </div>
        <Container>
          <Row className="mt-80">
            <Col md={9}>
                <h2 className='title-h2'>Tendo listas de espera, <br /><span className="highlight-title">porque precisa</span> do Larin?</h2>
                <p className='mt-20 mb-60 text-18'>Os espaços e serviços de apoio aos nosso pais e avós primariamente existem para melhor servir os nossos entes queridos e amados, mas como instituições precisam de continuar a evoluir, para expandir o seu alcance, a nível local, regional, nacional e até mesmo internacional. Quanto maior exposição tiver, maior interesse terão nos seus espaços e serviços. Com isto, conseguirá atrair as melhores pessoas para os vossos lugares privados. <br /><br />
                Num universo cada vez mais digital, não obter a devida pegada bem marcada, fará que os outros espaços possam passar à frente e consigam obter melhores resultados… <br />
                Tal como na vida, também através do Larin, a pegada irá ser criado com uma evolução natural. <br /><br />
                <b>De que está à espera?</b>
                </p>
                <Link to="/register" className="btn2">Registar agora</Link>
            </Col>
          </Row>
          <div className="mt-100 hscroll-container">
            <Container>
            {this.state.slides.map((value, index) => {
							return (
                <div className="hscroll-container2">
                  {value.IMAGE_POSITION == 2 && value.SLIDE_WIDTH == 1 && value.TEXT != '' &&
                    <div className="hscroll-3">
                      <div dangerouslySetInnerHTML={{__html: value.TEXT}}></div>
                      <img src={global.config.uploadsPath2+value.IMAGE} />
                    </div>
                  }
                  {value.IMAGE_POSITION == 2 && value.SLIDE_WIDTH == 2 && value.TEXT != '' &&
                    <div className="hscroll-1">
                      <div dangerouslySetInnerHTML={{__html: value.TEXT}}></div>
                      <img src={global.config.uploadsPath2+value.IMAGE} />
                    </div>
                  }
                  {value.IMAGE_POSITION == 1 && value.SLIDE_WIDTH == 1 && value.TEXT != '' &&
                    <div className="hscroll-3">
                      <img src={global.config.uploadsPath2+value.IMAGE} />
                      <div dangerouslySetInnerHTML={{__html: value.TEXT}}></div>
                    </div>
                  }
                  {value.IMAGE_POSITION == 1 && value.SLIDE_WIDTH == 2 && value.TEXT != '' &&
                    <div className="hscroll-1">
                      <div dangerouslySetInnerHTML={{__html: value.TEXT}}></div>
                      <img src={global.config.uploadsPath2+value.IMAGE} />
                    </div>
                  }
                  {value.SLIDE_WIDTH == 1 && value.TEXT == '' &&
                    <div className="hscroll-2">
                      <img src={global.config.uploadsPath2+value.IMAGE} />
                    </div>
                  }
                </div>
							)
						})}
            </Container>
          </div>
          <div className="mt-100 hscroll-fake">
            <button className="hscroll-arrow-left" onClick={() => this.hscrollLeft()}><img src={arrowNav}/></button>
            <button className="hscroll-arrow-right" onClick={() => this.hscrollRight()}><img src={arrowNav}/></button>
          </div>
          <Row className="mt-60 text-center">
            <Col md={12}>
              <Link to="/register" className="btn1">Registar agora</Link>
            </Col>
          </Row>
          <hr className='mt-100' />
          <Row className="mt-60 mb-60">
            <Col md={9}>
              <p className='notes'>
              <b>Notas</b> <br /> 
              1 Os espaços e serviços de apoio aos nosso pais e avós primariamente existem para melhor servir os nossos entes queridos e amados, mas como instituições precisam de continuar a evoluir, para expandir o seu alcance, a nível local, regional, nacional e até mesmo internacional. Quanto maior exposição tiver, maior interesse terão nos seus espaços e serviços. Com isto, conseguirá atrair as melhores pessoas para os vossos lugares privados. <br /> 
              2 Num universo cada vez mais digital, não obter a devida pegada bem marcada, fará que os outros espaços possam passar à frente e consigam obter melhores resultados… Tal como na vida, também através do Larin, a pegada irá ser criado com uma evolução natural. <br /> 
              3 Os espaços e serviços de apoio aos nosso pais e avós primariamente existem para melhor servir os nossos entes queridos e amados, mas como instituições precisam de continuar a evoluir, para expandir o seu alcance, a nível local, regional, nacional e até mesmo internacional. Quanto maior exposição tiver, maior interesse terão nos seus espaços e serviços. Com isto, conseguirá atrair as melhores pessoas para os vossos lugares privados. <br /> 
              4 Num universo cada vez mais digital, não obter a devida pegada bem marcada, fará que os outros espaços possam passar à frente e consigam obter melhores resultados… <br /> 
              Tal como na vida, também através do Larin, a pegada irá ser criado com uma evolução natural.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Home;
