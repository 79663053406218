import React from 'react';
import '../index.css';
import '../config.js';
import logo from '../images/logo.svg';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }
  

  render() {
    return (
      <Navbar className="navbar-header" id="navbar-top" expand="lg">
          <div className="logo"><Link to="/"><img src={logo} className="header-logo" alt="logo" /></Link></div>
          <ul className="header-navbar">
            <li className="header-navbar-item2"><Link to="/register">Adicionar um lar</Link></li>
            {/*<li className="header-navbar-item"><Link to="#">Ajuda</Link></li>
            <li className="header-navbar-item"><Link to="/register">Registar-se</Link></li>*/}
            <li className="header-navbar-item"><a href="https://admin.larin.pt/login">Iniciar sessão</a></li>
          </ul>
      </Navbar>
    );
  }
}

export default Header;
